import React from 'react';
import $ from 'jquery'
// import 'venobox'  doesnt do anything intresting. Thinking of just deleting it

import image1 from "../img/gallery/gallery-1.jpg"
import image2 from "../img/gallery/gallery-2.jpg"
import image3 from "../img/gallery/gallery-3.jpg"
import image4 from "../img/gallery/gallery-4.jpg"
import image5 from "../img/gallery/gallery-5.jpg"
import image6 from "../img/gallery/gallery-6.jpg"
import image7 from "../img/gallery/paard-gedragstraining-trailerladen.jpg"
import image8 from "../img/gallery/paard-gedrag-schrik-obstakel-training.jpg"

const Gallery = () => {

    React.useEffect(() => {
        // Initiate venobox lightbox
    // $(document).ready(function () {
    //     $('.venobox').venobox();
    // });
    }, [])

    return (
        <section id="gallery" className="gallery">
            <div className="container-fluid">

                <div className="section-title">
                    <h2>Fotogallerij</h2>
                    {/* <p>Ut possimus qui ut temporibus culpa velit eveniet modi omnis est adipisci expedita at voluptas atque vitae autem.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image1} className="venobox" data-gall="gallery-item">
                                <img src={image1} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image2} className="venobox" data-gall="gallery-item">
                                <img src={image2} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image3} className="venobox" data-gall="gallery-item">
                                <img src={image3} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image4} className="venobox" data-gall="gallery-item">
                                <img src={image4} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image5} className="venobox" data-gall="gallery-item">
                                <img src={image5} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image6} className="venobox" data-gall="gallery-item">
                                <img src={image6} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image7} className="venobox" data-gall="gallery-item">
                                <img src={image7} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-6 p-0">
                        <div className="gallery-item">
                            <a href={image8} className="venobox" data-gall="gallery-item">
                                <img src={image8} alt="Gallery Item 1" className="img-fluid" />
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Gallery;
