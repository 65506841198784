import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery'

import '../icofont/icofont.min.css'
// import 'owl.carousel'

import testimonial1 from '../img/testimonials/testimonials-1.jpg'
import testimonial2 from '../img/testimonials/testimonials-2.jpg'
import testimonial3 from '../img/testimonials/testimonials-3.jpg'
import testimonial4 from '../img/testimonials/testimonials-4.jpg'
import testimonial5 from '../img/testimonials/testimonials-5.jpg'

const Testimonials = () => {

    React.useEffect(() => {
		const buttons = $(".testimonials-carousel>ul.owl-dots>li>button")
		buttons.map((i, button) => $(button).html(''))
	}, [])

    const carousel = {
        arrows: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        // customPaging: <button></button>,
        dotsClass: "owl-dots"
    }

    return (<>
        <section id="testimonials" className="testimonials">
            <div className="container">

                <Slider {...carousel} className=" testimonials-carousel">

                    <div className="testimonial-item">
                        <img src={testimonial1} className="testimonial-img" alt="" />
                        <h3>Eva </h3>
                        <h4>Noordwijk</h4>
                        <div className="stars">
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                        </div>
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            Angela kijkt ontzettend goed naar mens én paard. Ze past het lesschema aan naar wat ze ziet bij het paard
                            en kind dat bij haar is. Lisa heeft het grondwerk goed aangeleerd gekregen door Angela. Mede door dat
                            Angela vol vertrouwen, enthousiasme en vakkundig te werk gaat. Hierdoor heeft Lisa nu veel meer
                            zelfvertrouwen gekregen en geniet ze elke keer weer wanneer ze naar Karel en Frankie mag.
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                    </div>

                    <div className="testimonial-item">
                        <img src={testimonial2} className="testimonial-img" alt="" />
                        <h3>Jenny </h3>
                        <h4>Noordwijkerhout</h4>
                        <div className="stars">
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                        </div>
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            Kumar durfde niet de trailer in en er in te blijven, als hij al een ander geluid hoorde stoof hij naar
                            achteren. Ook is hij bang voor veel dingen zoals een stukje plastic, een fietser met een rammelende
                            fietskrat. Ik wilde leren hoe ik kumar daar in het beste kan begeleiden. Angela heeft mij er bij geholpen
                            door met de trailer te trainen en los daarvan schriktraining. Ze luisterde goed naar wat ik wilde leren,
                            ze kon me al doende goed uitleggen hoe ik kumar moet begeleiden. Ik ben me meer bewust geworden hoe mijn
                            houding en correctie invloed heeft. Dit heeft mij en uiteindelijk kumar zelfvertrouwen gegeven. We kunnen
                            weer verder!
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                    </div>

                    <div className="testimonial-item">
                        <img src={testimonial3} className="testimonial-img" alt="" />
                        <h3>Tessa </h3>
                        <h4>Ouderkerk a/d Amstel</h4>
                        <div className="stars">
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                        </div>
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            Angela heeft mij en mijn merrie geholpen met grondwerk. Omdat dit redelijk nieuw voor mij is ben ik erg
                            blij met haar duidelijke uitleg en geduld! Thanks voor de fijne lessen.
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                    </div>

                    <div className="testimonial-item">
                        <img src={testimonial4} className="testimonial-img" alt="" />
                        <h3>Koosje & Julie </h3>
                        <h4>Noordwijkerhout</h4>
                        <div className="stars">
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                        </div>
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            De wijze waarop Angela de les opbouwde, vond ik mooi. Met veel enthousiasme, respect en empathie gaf
                            Angela, mij en mijn pony Jack de ruimte om de grondwerk oefeningen goed uit te voeren.
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                    </div>

                    <div className="testimonial-item">
                        <img src={testimonial5} className="testimonial-img" alt="" />
                        <h3>Bärbel </h3>
                        <h4>Oegstgeest</h4>
                        <div className="stars">
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                            <i className="icofont-star"></i>
                        </div>
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            Angela heeft ons erg geholpen met het laden van mijn koppige Haflinger. Voordat Angela kwam helpen, hebben
                            we al veel methoden geprobeerd zonder succes. Het liet ons alleen maar gefrustreerd achter. Maar met
                            Angela's duidelijke communicatie met het paard en rustige benadering voelt mijn paard zich nu op zijn
                            gemak in de trailer. En het ging veel sneller dan verwacht. Het is echt geweldig en ik zou haar nog een
                            keer bellen als ik problemen heb met mijn paard.
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                    </div>
                </Slider>
            </div>
        </section>
    </>)
}

export default Testimonials;