import React from 'react';
// Vergeet niet de CSS-bestanden te importeren die de gebruikte classes bevatten, zoals icofont en de algemene styling
// Bijvoorbeeld:
// import 'path-to-css/icofont.css';
// import 'path-to-css/styles.css';
// Importeer ook de benodigde afbeeldingen als modules in React om te zorgen dat de paden correct werken
// Bijvoorbeeld:
import instructieImg from '../img/instructie.png';
import trainingImg from '../img/paarden-training-trailerladen.jpg';
import jeugdImg from '../img/jeugd-paardencursus.jpg';

const Specials = () => {
  return (
    <section id="specials" className="specials">
      <div className="container">

        <div className="section-title">
          <h2>Bekijk <span>de mogelijkheden</span></h2>
          {/* <p>Heb je nog vragen of andere wensen? neem contact op voor meer mogelijkheden.</p> */}
        </div>

        <div className="row about-container">

          <div className="col-lg-6 content order-lg-1 order-2">
            <h4>Instructie</h4>
            <p>
              Communiceren met paarden kun je leren en verder ontwikkelen. Als je maar weet waar je
              naar moet kijken, weet hoe jij je lichaam in kan zetten en hoe jij veilig kunt blijven.
              Ik geef lessen aan iedereen die graag met paarden om gaat. Wil jij leren longeren met de dubbele
              lijn, of leren hoe je een paard bomproof maakt door middel van een schrik- en obstakeltraining?
              Neem contact met mij op, dan kunnen we aan de slag!
            </p>
          </div>

          <div className="col-lg-6 background pb-4 order-lg-2 order-1 wow fadeInUp">
            <img src={instructieImg} className="img-fluid" alt="Instructie" />
          </div>
        </div>

        <div className="row about-extra">
          <div className="col-lg-6 pb-4 wow fadeInUp">
            <img src={trainingImg} className="img-fluid" alt="Gedragstraining" />
          </div>
          <div className="col-lg-6 wow fadeInUp pt-5 pt-lg-0">
            <h4>Gedragstraining</h4>
            <p>
              Ervaar jij de omgang met jouw paard wel eens als een uitdaging? En zou je graag zien dat daar
              verandering in komt? Zou je graag zien dat hij zo de trailer in loopt? Of rustig wacht tot hij de stal uit
              mag? En ook nog eens meewerkt als de hoefsmid komt.. Ideaal toch?
            </p>
            <p>
              Je kunt van mij een professioneel opgezet trainingsplan verwachten en een heldere uitleg aan jou. Zodat je weet waar jij
              op kunt letten, begrijpt wat jouw paard doet en je uiteindelijk zelfstandig verder kunt!
            </p>
          </div>
        </div>

        <div className="row about-extra">
          <div className="col-lg-6 wow fadeInUp order-1 order-lg-2">
            <img src={jeugdImg} className="img-fluid" alt="Jeugd" />
          </div>

          <div className="col-lg-6 wow fadeInUp pt-4 pt-lg-0 order-2 order-lg-1">
            <h4>Jeugd</h4>
            <p>
              Voor de jonge paardenliefhebbers onder ons zijn er leuke en educatieve lesjes en cursussen,
              aangepast aan het kind. De lesjes en de cursus heeft als doel om het kind veilig en plezierig te laten ervaren hoe om te gaan
              met Karel de shetlander. De basisvaardigheden zoals het opzadelen en het wandelen ga je leren in
              een veilige en kleine ruimte.
            </p>
            <p>
              Ook voor de meer ervaren paardenliefhebbers zijn er lessen met onze Fjord die meer uitdaging bieden.
              Zoals leren longeren met de dubbele lijnen of een schriktraining.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Specials;
