import React from 'react';
import '../icofont/icofont.min.css';
import $ from 'jquery'

import logo from '../img/logo.png';

const Header = () => {

  React.useEffect(() => {
    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
        $('#topbar').addClass('topbar-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
        $('#topbar').removeClass('topbar-scrolled');
      }
    });

    // Navigation active state on scroll
		var nav_sections = $('section');
		var main_nav = $('.nav-menu, #mobile-nav');

		$(window).on('scroll', function () {
			var cur_pos = $(this).scrollTop() + 200;

			nav_sections.each(function () {
				var top = $(this).offset().top,
					bottom = top + $(this).outerHeight();

				if (cur_pos >= top && cur_pos <= bottom) {
					if (cur_pos <= bottom) {
						main_nav.find('li').removeClass('active');
					}
					main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
				}
				if (cur_pos < 300) {
					$(".nav-menu ul:first li:first").addClass('active');
				}
			});
		});

		// Mobile Navigation
		if ($('.nav-menu').length) {
			var $mobile_nav = $('.nav-menu').clone().prop({
				class: 'mobile-nav d-lg-none'
			});
			$('body').append($mobile_nav);
			$('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
			$('body').append('<div class="mobile-nav-overly"></div>');
	
			$(document).on('click', '.mobile-nav-toggle', function (e) {
				$('body').toggleClass('mobile-nav-active');
				$('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
				$('.mobile-nav-overly').toggle();
			});
	
			$(document).on('click', '.mobile-nav .drop-down > a', function (e) {
				e.preventDefault();
				$(this).next().slideToggle(300);
				$(this).parent().toggleClass('active');
			});
	
			$(document).click(function (e) {
				var container = $(".mobile-nav, .mobile-nav-toggle");
				if (!container.is(e.target) && container.has(e.target).length === 0) {
					if ($('body').hasClass('mobile-nav-active')) {
						$('body').removeClass('mobile-nav-active');
						$('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
						$('.mobile-nav-overly').fadeOut();
					}
				}
			});
		} else if ($(".mobile-nav, .mobile-nav-toggle").length) {
			$(".mobile-nav, .mobile-nav-toggle").hide();
		}

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $(window).scrollTop(0)
      return false;
    });
  }, [])

  return (
    <header id="header" className="fixed-top d-flex align-items-center header-transparent">
      <div className="container d-flex align-items-center">

        <div className="logo mr-auto">
          {/* <h1 className="text-light"><a href="index.html"><span>Angela </span>Paard&Gedrag</a></h1> */}
          {/* Uncomment hieronder als je liever een afbeelding als logo gebruikt */}
          <a href="#hero"><img src={logo} alt="logo" className="img-fluid" /></a>
        </div>

        <nav className="nav-menu d-none d-lg-block ">
          <ul>
            <li className="active"><a href="#hero">Home</a></li>
            <li><a href="#about">Over mij</a></li>
            <li><a href="#specials">Mogelijkheden</a></li>
            <li><a href="#menu">Prijzen</a></li>
            <li><a href="#gallery">Foto gallerij</a></li>
            <li><a href="#testimonials">Recenties</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>{/* .nav-menu */}

      </div>
    </header>
  );
}

export default Header;
