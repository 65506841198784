import React from 'react';

const Menu = () => {
  return (
    <section id="menu" className="menu">
      <div className="container">
        <div className="section-title">
          <h2>Prijzen</h2>
          <br />
          <p>
            Prijzen vanaf €25,00 per 30 minuten
            <br /><br />
            Neem <a href="#contact">contact</a> op voor meer informatie over de tarieven.
          </p>
          {/* <p>Klik op de knop om de prijslijst te downloaden.<br /><br /><br /></p>
          <a href="docs/prijslijst2021.pdf" className="btn-menu animate__animated animate__fadeInUp scrollto">Download de prijslijst</a> */}
        </div>
      </div>
    </section>
  );
}

export default Menu;
