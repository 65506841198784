import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css'
import './index.css';
// import './main.js'

import TopBar from './sections/TopBar';
import Header from './sections/Header';
import Hero from './sections/Hero';
import About from './sections/About';
import Specials from './sections/Specials';
import Menu from './sections/Menu';
import WhyUs from './sections/WhyUs';
import Gallery from './sections/Gallery';
import MoreInfo from './sections/MoreInfo';
import Testimonials from './sections/Testimonials';
import Contact from './sections/Contact';
import Footer from './sections/Footer';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TopBar />
    <Header />
    <Hero />
    <About />
    <WhyUs />
    <Specials />
    <Menu />
    <Gallery />
    <MoreInfo />
    <Testimonials />
    <Contact />
    <Footer />
    <a href="#" className="back-to-top"><i className="icofont-simple-up"></i></a>


  </React.StrictMode>
);