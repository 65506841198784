import React from "react";

import transparentLogo from '../img/logotransparant.png'
import equinelogo from '../img/e-quine_logo_liggend.png'
import equineinstructor from '../img/certified-instructor-equine.png'

const MoreInfo = () => {
  return (<>
    <section id="chefs" className="chefs">
      <div className="container">

        <div className="section-title">
          <h2>Meer <span>informatie ?</span></h2>
          <p>Neem gerust contact op. Of klik op de e-quine.com voor meer informatie over mij</p>
        </div>

        <div className="row sm:flex-row flex-col">
          <div className="col flex justify-center">
            <div className="h-full flex items-center p-6">
              <a className="flex justify-center" target="_blank" href="https://e-quine.com/teachers/angela-jansen/">
                  <img src={equineinstructor} className="w-[73%]" alt="equine certified instructor"></img>
              </a>
            </div>
          </div>


          <div className="col">
            <div className="p-6 h-full flex items-center">
              <img src={transparentLogo} className="img-fluid" alt="logo" />
            </div>
          </div>

          {/* 
          <div className="col-3">
            <div className="p-6 h-full flex items-center">
              <img src={equineinstructor} className="img-fluid" alt="logo" />
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col">
            <div className="flex justify-center items-center">
              <img src="" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section >

  </>)
}

export default MoreInfo;