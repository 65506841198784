import React from 'react';

const WhyUs = () => {
  return (
    <section id="why-us" className="why-us">
      <div className="container">

        <div className="section-title">
          <h2>Waarom  <span>Angela Paard & Gedrag?</span></h2>
          <p>Centraal staat het paard en zijn gedrag. Met als doel de optimale samenwerking en communicatie
            met mens en paard te bereiken.</p>
        </div>

        <div className="row">

          <div className="col-lg-4">
            <div className="box">
              <span>01</span>
              <h4>Deskundig</h4>
              <p>Gediplomeerd KNHS instructeur Paard & Gedrag</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <span>02</span>
              <h4>Oog voor paard en mens</h4>
              <p>Traint met zachte hand en zonder stress in kleine stappen</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="box">
              <span>03</span>
              <h4>Op maat gemaakt</h4>
              <p>Voor iedereen een passend lesplan op het juiste niveau.</p>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
}

export default WhyUs;
