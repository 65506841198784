import React from "react";


const Contact = () => {
    return (<>
        <section id="contact" className="contact my-4">
            <div className="container">

                <div className="section-title">
                    <h2><span>Contact</span> opnemen</h2>
                    <p>Stel je vraag door contact op te nemen met de mogelijkheden hieronder </p>
                </div>
            </div>

            {/* <div className="map">
                <iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2441.7518795033293!2d4.494443151247561!3d52.26605056267132!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5c1dac51b39f5%3A0x380e202a52de71b5!2sDoctor%20Ariensstraat%205%2C%202211%20BA%20Noordwijkerhout!5e0!3m2!1snl!2snl!4v1611174288241!5m2!1snl!2snl" frameborder="0" allowfullscreen></iframe>
            </div> */}

            <div className="container mt-5">
                <div className="info-wrap">
                    <div className="row items-center">
                        <div className="col-lg-4 col-md-6 info">
                            <i className="icofont-google-map"></i>
                            <h4>Locatie:</h4>
                            <p>Langevelderlaan 12<br />2204BD, Noordwijk</p>
                        </div>

                        {/* <div className="col-lg-3 col-md-6 info mt-4 mt-lg-0">
                            <i className="icofont-clock-time icofont-rotate-90"></i>
                            <h4>Openingstijden:</h4>
                            <p>Maandag-Vrijdag:<br>8:30 - 17:00</p>
                        </div> */}

                        <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
                            <i className="icofont-envelope"></i>
                            <h4>Email:</h4>
                            <p className="text-wrap">info@angelapaardengedrag.nl<br /></p>
                        </div>

                        <div className="col-lg-4 col-md-6 info mt-4 mt-lg-0">
                            <i className="icofont-phone"></i>
                            <h4>Telefoon:</h4>
                            <p><a href="tel:+31619989052">06-19989052</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section >
    </>)
}

export default Contact;