import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery'

import slide1 from '../img/slide/paardencoaching-paard-en-gedrag-noordwijkerhout.jpg';
import slide2 from '../img/slide/paardentraining-paardengedragscoach.jpg';
import slide3 from '../img/slide/pony-paarden-schriktraining-gedrag-ponyles.jpg';

const Hero = () => {
	const carousel = {
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 7000,
		pauseOnHover: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		waitForAnimate: false,
		// nextArrow: <SampleNextArrow />,
		// prevArrow: <SamplePrevArrow />,
		// appendDots: dots => (
		// 	<div style={{ backgroundColor: "#ddd", borderRadius: "10px", padding: "10px" }}>
		// 		<ul style={{ margin: "0px" }}> {dots} </ul>
		// 	</div>
		// ),
		// customPaging: i => (
		// 	<div style={{ width: "30px", color: "blue", border: "1px blue solid" }} ></div>
		// )
	}

	return (
		<section id="hero" className="h-screen">
			<Slider {...carousel} className='carousel' >

				<div className='' >
					<div className="carousel-content bg-center" style={{ background: `url(${slide1})`, backgroundPosition: '57% 0%' }}>
						<h2 className="">Paard</h2>
						<p className="">Heb jij interesse in het gedrag van paarden?</p>
						<div>
							<a href="#specials" className="btn-menu ">Mogelijkheden</a>
							<a href="#contact" className="btn-book ">Contact</a>
						</div>
					</div>
				</div>

				<div>
					<div className="carousel-content bg-center" style={{ background: `url(${slide2})`, backgroundPosition: '48% 0%' }}>
						<h2 className="animate__animated animate__fadeInDown">Passie</h2>
						<p className="animate__animated animate__fadeInUp">Wil je jouw passie voor paarden verder ontwikkelen?</p>
						<div>
							<a href="#specials" className="btn-menu animate__animated animate__fadeInUp scrollto">Mogelijkheden</a>
							<a href="#contact" className="btn-book animate__animated animate__fadeInUp scrollto">Contact</a>
						</div>
					</div>
				</div>

				<div>
					<div className="carousel-content" style={{ background: `url(${slide3})`, backgroundPosition: '10% 0%' }}>
						<h2 className="animate__animated animate__fadeInDown">Plezier</h2>
						<p className="animate__animated animate__fadeInUp">Zoek je een kindvriendelijke ponyles waar met plezier de basisvaardigheden worden aangeleerd?</p>
						<div>
							<a href="#specials" className="btn-menu animate__animated animate__fadeInUp scrollto">Mogelijkheden </a>
							<a href="#contact" className="btn-book animate__animated animate__fadeInUp scrollto">Contact</a>
						</div>
					</div>
				</div>

			</Slider>
		</section >
	);
}

export default Hero;
