import React from "react";


const Footer = () => {

    return (<>
        <footer id="footer">
            <div className="container mt-4 mb-3">
                <h3>Angela Paard & Gedrag</h3>
                <p>Gedragstraining, trailerladen, schrik- en obstakeltraining, grondwerk, loswerken, werken met dubbele longe,
                    lessen voor kinderen en volwassenen</p>
                {/* <div className="social-links">
        <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
        <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
        <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
        <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a>
        <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a>
      </div> */}
                <div className="copyright">
                    &copy; Copyright <strong><span>Angela Jansen</span></strong>.
                </div>
                <div className="credits">
                    {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/delicious-free-restaurant-bootstrap-theme/ --> */}
                </div>
            </div>
        </footer>
    </>)
}

export default Footer;