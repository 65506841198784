import React from 'react';

import '../icofont/icofont.min.css';

const TopBar = () => {

  // This code is already inserted in the Header component
  // ===============================================================
  // React.useEffect(() => {
  //   // Toggle .header-scrolled class to #header when page is scrolled
  //   $(window).scroll(function () {
  //     if ($(this).scrollTop() > 100) {
  //       $('#topbar').addClass('topbar-scrolled');
  //     } else {
  //       $('#topbar').removeClass('topbar-scrolled');
  //     }
  //   });
  // }, [])

  return (
    <section id="topbar" className="d-none d-lg-flex align-items-center fixed-top topbar-transparent">
      <div className="container text-right" >
        <i className="icofont-phone"></i> 06-19989052
        <i className="icofont-google-map"></i> Langevelderlaan 12 Noordwijk
      </div>
    </section>
  );
}

export default TopBar;
